import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { distinctUntilChanged } from 'rxjs/operators';
import xdk from '@accedo/xdk-core';
import { focusManager } from '@accedo/vdkweb-navigation';
import { pageRedux } from '@accedo/vdkweb-tv-ui';

import { ProtectedRoute } from './routing/protected-route.component';
import { SplashPageComponent } from './pages/splash/splash-page.component';
import { WelcomePageComponent } from './pages/welcome/welcome-page.component';
import { LoginPageComponent } from './pages/login/login-page.component';
import { LoginConfirmationPageComponent } from './pages/login/login-confirmation.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { OpenAccessConfirmationPageComponent } from './pages/open-access/open-access-confirmation.component';
import { OpenAccessExpiredPageComponent } from './pages/open-access/open-access-expired.component';
import { AllChannelsPage } from './pages/all-channels-page/all-channels.component';
import { NowPlayingPageComponent } from './pages/now-playing/now-playing.component';
import { appRouteConstants } from './routing/app.route.constants';

import { NavigationMenu } from './components/navigation-menu/navigation-menu.component';

import './preflight';
import './App.scss';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { SearchResultComponent } from './pages/search-page/search-result-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { CategoryPage } from './pages/category-page/category.component';
import { useAuth } from './auth/provide-auth';

import {
  ServiceFactory,
  StorageService,
  StorageKeyConstant,
  SettingsConstants,
  VideoPlayerService,
  SessionMonitorService,
  APP_ERROR_STATE_CONST,
  AuthenticationService,
  BypassMonitorService,
  MediaPlayerService,
  SettingsService,
} from './servicelib';
import { NavigationService } from './sxmservicelayer/navigation/navigation.service';
import { ViewAllPage } from './pages/view-all-page.tsx/view-all.component';
import { CollectionPage } from './pages/collection-page/collection.component';
import { EpisodeListingPage } from './pages/episode-listing-page/episode-listing.component';
import {
  pageRight,
  pageLeft,
  keyPressed,
  ffwKeyPressed,
  rewindKeyPressed,
} from './redux/action/xdk.action';
import { PodcastsVideosPageComponent } from './pages/podcasts-videos/podcasts-videos-page.component';
import { FavoritesPageComponent } from './pages/favorites-page/favorites-page.component';
import { RecentPageComponent } from './pages/recent-page/recent-page.component';
import { EnhancedEdpPage } from './pages/enhanced-edp-page/enhanced-edp-page.component';
import { HandleAutoplayOnStartup } from './components/handle-autoplay-on-startup';
import { InactivityTracker } from './components/inactivity-tracker';
import Modal from './components/Modal/Modal';
import UpNext from './components/up-next/up-next.component';
import { SkippingTracker } from './components/skipping-tracker';

declare const $badger: any;

const VIDEO_WRAPPER_ID = 'video-wrapper';
const AUTOPLAY_STATES = {
  INITIALIZATION: 'INITIALIZATION',
  AUTOPLAY: 'AUTOPLAY',
  COMPLETE: 'COMPLETE',
};

const initXdk = async (
  callback: (message: string) => void,
  history: any,
  dispatch: any,
) => {
  await xdk.load();
  const platform = xdk.system.getDeviceType();

  console.info(`XDK is loaded in the platform: ${platform}`);
  callback(`XDK is loaded in the platform: ${platform}`);

  const { actions: pageActions } = pageRedux;
  const { UP, DOWN, LEFT, RIGHT, OK, BACK, EXIT } = xdk.CONSTANT.KEY;
  const KEY_EVENT_PREFIX = 'device:vkey:';
  focusManager.setPersistTrail(true);

  xdk.environment.addEventListener(xdk.environment.SYSTEM.KEYDOWN, ({ id }) => {
    switch (id) {
      case UP.id:
      case DOWN.id:
      case LEFT.id:
      case RIGHT.id: {
        const direction = `next${id.substring(KEY_EVENT_PREFIX.length)}`;
        focusManager.navigateFocus(direction);
        dispatch(keyPressed());

        if (history.location.pathname === appRouteConstants.NOW_PLAYING) {
          //Dispatches actions so the right and left key presses for skipping
          //can be detected in the Now Playing screen
          if (id === LEFT.id) {
            dispatch(pageLeft());
          } else if (id === RIGHT.id) {
            dispatch(pageRight());
          }
        }

        break;
      }
      case OK.id:
        focusManager.click();
        dispatch(keyPressed());
        break;
      case BACK.id:
        // Connect BACK KeyDown to dispatch pageAction
        dispatch(pageActions.pageBack());
        dispatch(keyPressed());
        break;
      case EXIT.id:
        // Connect EXIT KeyDown to dispatch pageAction
        dispatch(pageActions.pageExit());
        break;
      default:
        break;
    }
  });
};

const App = () => {
  const [, setMessage] = useState('Loading XDK...');
  const [dismissSplashScreen, shouldDismissSplashScreen] = useState(false);
  const [isCoreMenuOpen, setIsCoreMenuOpen] = useState(true);
  const [autoplayOnStartup, setAutoplayOnStartup] = useState(
    AUTOPLAY_STATES.INITIALIZATION,
  );
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const SIMULTANEOUS_LISTENING_MODAL_DATA = {
    title:
      'Only one device can be listening at a time and another is logged in.',
    text: 'Would you like to continue on this device?',
    actions: [
      {
        label: 'Continue',
        action: () => {
          const authenticationService = ServiceFactory.getInstance(
            AuthenticationService,
          ) as AuthenticationService;
          authenticationService.reclaimSession();
          setHasError(false);
        },
      },
      {
        label: 'Cancel',
        action: () => {
          if (typeof $badger !== 'undefined') {
            $badger.shutdown();
          }
          setHasError(false);
        },
      },
    ],
  };
  const [modalData, setModalData] = useState(SIMULTANEOUS_LISTENING_MODAL_DATA);
  useEffect(() => {
    if (dismissSplashScreen) {
      const sessionMonitorService = ServiceFactory.getInstance(
        SessionMonitorService,
      ) as SessionMonitorService;

      sessionMonitorService.appErrorState.subscribe({
        next: payload => {
          if (
            payload.IS_SIMULTANEOUS_LISTEN ===
            APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_ENABLED
          ) {
            setModalData(SIMULTANEOUS_LISTENING_MODAL_DATA);
            setHasError(true);
          }
        },
      });
    }
  }, [dismissSplashScreen]);

  useEffect(() => {
    initXdk(setMessage, history, dispatch);
  }, []);

  const auth: any = useAuth();
  useEffect(() => {
    if (dismissSplashScreen) {
      const mediaPlayerService = ServiceFactory.getInstance(
        MediaPlayerService,
      ) as MediaPlayerService;

      const onGlobalKeyDown = event => {
        const { keyCode } = event;

        if (
          keyCode === 37 ||
          keyCode === 38 ||
          keyCode === 39 ||
          keyCode === 40 ||
          keyCode === 8
        ) {
          //Prevents undesired scrolling when up and down arrow keys are pressed or going back
          event.preventDefault();
        }

        if (auth && auth.userSession && auth.userSession.authenticated) {
          if (keyCode === 179) {
            //PLAY/PAUSE of X1 remote controller
            dispatch(keyPressed());
            mediaPlayerService.mediaPlayer?.togglePausePlay().subscribe();
          } else if (keyCode === 227) {
            //REWIND
            dispatch(keyPressed());
            dispatch(rewindKeyPressed());
          } else if (keyCode === 228) {
            //FFWD
            dispatch(keyPressed());
            dispatch(ffwKeyPressed());
          }
        }
      };

      window.addEventListener('keydown', onGlobalKeyDown);

      return () => window.removeEventListener('keydown', onGlobalKeyDown);
    }
  }, [dismissSplashScreen]);

  useEffect(() => {
    const navigationService = ServiceFactory.getInstance(
      NavigationService,
    ) as NavigationService;
    navigationService.history = history;
  }, []);

  useEffect(() => {
    if (dismissSplashScreen) {
      const storageService = ServiceFactory.getInstance(
        StorageService,
      ) as StorageService;

      if (!storageService.getItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP)) {
        storageService.setItem(
          StorageKeyConstant.AUTOPLAY_ON_STARTUP,
          SettingsConstants.OFF,
        );
      }
      const isAutoplayOnStartupOn =
        storageService.getItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP) ===
        SettingsConstants.ON;
      if (isAutoplayOnStartupOn) {
        setAutoplayOnStartup(AUTOPLAY_STATES.AUTOPLAY);
      }
    }
  }, [dismissSplashScreen]);

  const videoWrapperRef = useRef(null);
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted && isMounted.current && dismissSplashScreen) {
      const videoPlayerService = ServiceFactory.getInstance(
        VideoPlayerService,
      ) as VideoPlayerService;

      if (videoWrapperRef && videoWrapperRef.current) {
        videoPlayerService.init(videoWrapperRef.current, VIDEO_WRAPPER_ID);
        videoPlayerService.subscribeToStartVideo();
      }
    } else {
      isMounted.current = true;
    }
  }, [videoWrapperRef.current, dismissSplashScreen]);

  /**** GUP AND IT BYPASS MODAL LOGIC - START  ******/

  const bypassSubscriptionRef = useRef(null);
  const [scanForBypass, setScanForBypass] = useState(false);
  useEffect(() => {
    if (
      dismissSplashScreen &&
      auth &&
      auth.userSession &&
      auth.userSession.authenticated &&
      auth.userSession.username
    ) {
      const bypassMonitorService = ServiceFactory.getInstance(
        BypassMonitorService,
      ) as BypassMonitorService;

      if (scanForBypass) {
        bypassSubscriptionRef.current &&
          bypassSubscriptionRef.current.unsubscribe();
      }

      bypassSubscriptionRef.current = bypassMonitorService.bypassErrorState
        .pipe(
          distinctUntilChanged((prev, current) => {
            return (
              prev.GUP_BYPASS === current.GUP_BYPASS &&
              prev.GUP_BYPASS2 === current.GUP_BYPASS2 &&
              prev.IT_BYPASS === current.IT_BYPASS &&
              prev.SEARCH_BYPASS === current.SEARCH_BYPASS
            );
          }),
        )
        .subscribe(bypassErrorState => {
          if (bypassErrorState.GUP_BYPASS || bypassErrorState.GUP_BYPASS2) {
            const modalData = {
              title: "We're experiencing some technical difficulties.",
              text:
                'Some of your settings and preferences are temporarily unavailable.',
              actions: [
                {
                  label: 'OK',
                  action: () => {
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          } else if (bypassErrorState.IT_BYPASS) {
            const modalData = {
              title: "We're experiencing some technical difficulties.",
              text: 'Please try again later.',
              actions: [
                {
                  label: 'Dismiss',
                  action: () => {
                    const authenticationService = ServiceFactory.getInstance(
                      AuthenticationService,
                    ) as AuthenticationService;
                    authenticationService.reclaimSession();
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          } else if (bypassErrorState.SEARCH_BYPASS) {
            const modalData = {
              title: "Sorry, we're experiencing some technical difficulties.",
              text: 'Search is currently unavailable.',
              actions: [
                {
                  label: 'OK',
                  action: () => {
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          }
        });
    }
  }, [
    dismissSplashScreen,
    auth && auth.userSession && auth.userSession.authenticated,
    scanForBypass,
  ]);

  /**** GUP AND IT BYPASS MODAL LOGIC - END  ******/

  const [showUpNext, setShowUpNext] = useState(false);
  const [isAutoPlayOn, setIsAutoPlayOn] = useState(false);
  useEffect(() => {
    if (dismissSplashScreen) {
      const settingsService = ServiceFactory.getInstance(
        SettingsService,
      ) as SettingsService;

      const subscription = settingsService.settings.subscribe(() => {
        setIsAutoPlayOn(
          settingsService.getGlobalSettingValue(SettingsConstants.AUTO_PLAY) ===
            SettingsConstants.ON,
        );
      });

      return () => subscription.unsubscribe();
    }
  }, [dismissSplashScreen]);

  const location = useLocation();
  const [isSkippingTrackerEnabled, enableSkippingTracker] = useState(false);
  useEffect(() => {
    if (location.pathname.includes(appRouteConstants.NOW_PLAYING)) {
      enableSkippingTracker(false);
    } else if (!isSkippingTrackerEnabled) {
      enableSkippingTracker(true);
    }
  }, [location.pathname]);

  return (
    <div className="app">
      <div id={VIDEO_WRAPPER_ID} ref={videoWrapperRef} />
      <Modal
        title={modalData.title}
        text={modalData.text}
        show={hasError}
        actions={modalData.actions}
        restoreFocus={focusManager.getCurrentFocus() || 'CORE_MENU'}
      />
      {dismissSplashScreen &&
        (autoplayOnStartup === AUTOPLAY_STATES.INITIALIZATION ||
          autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY) && (
          <HandleAutoplayOnStartup
            AUTOPLAY_STATES={AUTOPLAY_STATES}
            setAutoplayOnStartup={setAutoplayOnStartup}
            autoplayOnStartup={autoplayOnStartup}
          />
        )}
      {dismissSplashScreen && isAutoPlayOn && (
        <UpNext setShowUpNext={setShowUpNext} showUpNext={showUpNext} />
      )}
      {dismissSplashScreen && (
        <InactivityTracker
          setModalData={setModalData}
          auth={auth}
          setHasError={setHasError}
          setIsCoreMenuOpen={setIsCoreMenuOpen}
        />
      )}
      {dismissSplashScreen &&
        auth &&
        auth.userSession &&
        auth.userSession.authenticated &&
        auth.userSession.username &&
        isSkippingTrackerEnabled && <SkippingTracker showUpNext={showUpNext} />}
      <Switch>
        <Route exact={dismissSplashScreen} path={'/'}>
          <SplashPageComponent
            shouldDismissSplashScreen={shouldDismissSplashScreen}
          />
        </Route>
        <ProtectedRoute path={appRouteConstants.FT_WELCOME}>
          <WelcomePageComponent />
        </ProtectedRoute>
        <ProtectedRoute exact path={appRouteConstants.AUTH.LOGIN}>
          <LoginPageComponent />
        </ProtectedRoute>
        <ProtectedRoute path={appRouteConstants.AUTH.LOGIN_CONFIRMATION}>
          <LoginConfirmationPageComponent setScanForBypass={setScanForBypass} />
        </ProtectedRoute>
        <ProtectedRoute path={appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION}>
          <OpenAccessConfirmationPageComponent />
        </ProtectedRoute>
        <ProtectedRoute path={appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED}>
          <OpenAccessExpiredPageComponent />
        </ProtectedRoute>
        <ProtectedRoute path={appRouteConstants.NOW_PLAYING}>
          <NowPlayingPageComponent
            setShowUpNext={setShowUpNext}
            showUpNext={showUpNext}
          />
        </ProtectedRoute>

        <>
          <NavigationMenu
            isCoreMenuOpen={isCoreMenuOpen}
            setIsCoreMenuOpen={setIsCoreMenuOpen}
          />
          <ProtectedRoute path={`${appRouteConstants.HOME}/:superCategory`}>
            <HomePageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            path={`${appRouteConstants.FAVORITES}/:superCategory`}
          >
            <FavoritesPageComponent />
          </ProtectedRoute>
          <ProtectedRoute path={appRouteConstants.RECENTLY_PLAYED}>
            <RecentPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
            />
          </ProtectedRoute>
          <ProtectedRoute exact path={appRouteConstants.SEARCH}>
            <SearchPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
            />
          </ProtectedRoute>
          <ProtectedRoute path={`${appRouteConstants.SEARCH}/:query`}>
            <SearchPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
            />
          </ProtectedRoute>
          <ProtectedRoute path={appRouteConstants.SEARCH_RESULT}>
            <SearchResultComponent />
          </ProtectedRoute>
          <ProtectedRoute
            path={`${appRouteConstants.CATEGORY_PAGE}/:superCategory`}
          >
            <PodcastsVideosPageComponent
              setIsCoreMenuOpen={setIsCoreMenuOpen}
            />
          </ProtectedRoute>
          <ProtectedRoute path={`${appRouteConstants.ON_DEMAND.EPISODES_LIST}`}>
            <EpisodeListingPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute path={`${appRouteConstants.ENHANCED_EDP}`}>
            <EnhancedEdpPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute path={`${appRouteConstants.COLLECTION}`}>
            <CollectionPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute
            path={`${appRouteConstants.CATEGORY}/:category/:type`}
          >
            <CategoryPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute path={`${appRouteConstants.VIEW_ALL}/:carouselId`}>
            <ViewAllPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute path={appRouteConstants.ALL_CHANNELS}>
            <AllChannelsPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute path={appRouteConstants.APPLICATION_SETTINGS}>
            <SettingsPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
            />
          </ProtectedRoute>
        </>
      </Switch>
    </div>
  );
};

export default App;
