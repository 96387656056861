import React, { useEffect, useState, useRef } from 'react';
import { interval } from 'rxjs';
import { concatMap, catchError, takeWhile, skipWhile } from 'rxjs/operators';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Page } from '@accedo/vdkweb-tv-ui';
import { focusManager, navigationService } from '@accedo/vdkweb-navigation';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { RoundedButton } from '../../components/rounded-button';
import { TextInput } from '../../components/text-input';
import { Keyboard } from '../../components/keyboard';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { ReactComponent as ErrorCircle } from '../../assets/images/error-circle.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import './login-page.component.scss';
import {
  ServiceFactory,
  AuthenticationService,
  ResumeService,
  IAuthenticationResponse,
  ApiCodes,
  StorageService,
  StorageKeyConstant,
} from '../../servicelib';
import language from '../../assets/i18n/en-ca.json';
import SiriusXmTvQRCode from '../../assets/images/siriusxm-tv-qr-code.png';

const pageNav = { id: 'welcome-page' };
const usernameNav = { id: 'username-input', nextdown: 'password-input' };
const passwordNav = {
  id: 'password-input',
  nextup: 'username-input',
  nextdown: 'sign-in-button',
};
const signInButtonNav = {
  id: 'sign-in-button',
  skip: false,
  nextup: 'password-input',
};
const softKeyboardNav = { id: 'soft-keyboard' };

const getErrorMessage = code => {
  let errorMessage = '';
  const errorMessageStyle: any = { textAlign: 'left' };

  //TODO: Fetch text and translated text from props or service
  switch (code) {
    case ApiCodes.ACCOUNT_LOCKED:
    case ApiCodes.SR_REQ_FORBIDDEN:
      errorMessageStyle.paddingTop = '0.75rem';
      errorMessage = language.login.errors.modals.errForbidden.description;
      break;
    case ApiCodes.OAC_PASSWORD:
      errorMessage = language.login.errors.modals.oacLoginAttempt.description;
      break;
    case ApiCodes.EXPIRED_SUBSCRIPTION:
      errorMessage =
        language.login.errors.modals.expiredSubscription.description;
      break;
    case ApiCodes.INVALID_CREDENTIALS:
    default:
      errorMessage =
        "We don't recognize your username or password. Please try again or visit siriusxm.com/passwordreset to reset your username or password.";
  }

  return (
    <>
      <span className="error-circle">
        <ErrorCircle />
      </span>
      <span style={errorMessageStyle}>{errorMessage}</span>
    </>
  );
};

const footerText = () => (
  <>
    <span>Forgot username or password?</span>
    <span>Visit siriusxm.com/forgot</span>
  </>
);

//Needed to keep the latest value for isKeyboardOpen inside the observable's skipWhile closure
const shouldSkipPolling = {
  isKeyboardOpen: false,
};

const useLoginComponent = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isKeyboardOpen, openKeyboard] = useState(false);
  const [inputToEdit, setInputToEdit] = useState('');
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [authMessage, setAuthMessage] = useState(footerText);
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [registrationCode, setRegistrationCode] = useState('');
  const [pollFrequency, setPollFrequency] = useState(20);
  const [codeExpirationTime, setCodeExpirationTime] = useState(moment());
  const [regCodeCounter, setRegCodeCounter] = useState(1);
  const history = useHistory();
  const areCredentialsEntered = username && password;
  const authenticationService = ServiceFactory.getInstance(
    AuthenticationService,
  ) as AuthenticationService;
  const storageService = ServiceFactory.getInstance(
    StorageService,
  ) as StorageService;
  shouldSkipPolling.isKeyboardOpen = isKeyboardOpen;

  useEffect(() => {
    const subscription = authenticationService.createAlternateLogin().subscribe(
      ({ authenticationData }) => {
        const {
          registrationCode,
          pollFrequency,
          regExpiration,
        } = authenticationData;
        setPollFrequency(pollFrequency);
        setCodeExpirationTime(moment(regExpiration));
        setRegistrationCode(registrationCode);
      },
      error => {
        console.error(error);
      },
    );

    return () => subscription.unsubscribe();
  }, [regCodeCounter]);

  useEffect(() => {
    if (registrationCode) {
      const subscription = interval(pollFrequency * 1000)
        .pipe(
          skipWhile(() => {
            return shouldSkipPolling.isKeyboardOpen;
          }),
          takeWhile(() => {
            if (codeExpirationTime.diff(moment()) <= 0) {
              setRegCodeCounter(counter => counter + 1);
            }

            return codeExpirationTime.diff(moment()) > 0;
          }),
          concatMap(() =>
            authenticationService.completeAlternateLogin(registrationCode),
          ),
          catchError((err, caught) => caught),
          concatMap(() => {
            const resumeService = ServiceFactory.getInstance(
              ResumeService,
            ) as ResumeService;
            return resumeService.resume();
          }),
        )
        .subscribe(() => {
          history.replace(appRouteConstants.AUTH.LOGIN_CONFIRMATION);
        });

      return () => subscription.unsubscribe();
    }
  }, [registrationCode]);

  useEffect(() => {
    focusManager.changeFocus(usernameNav.id);

    return navigationService.listenToFocusEvent({
      id: signInButtonNav.id,
      fn: data => {
        if (data.currentFocus === signInButtonNav.id) {
          //Makes caret disappear from the password field, as it currently gives the illusion of both
          //the password input and Sign In button to be focused at the same timne.
          (document.activeElement as any).blur();
        }
      },
    });
  }, []);

  const onInputClick = inputId => {
    focusManager.changeFocus(softKeyboardNav.id);
    setInputToEdit(inputId);
    openKeyboard(true);
  };

  const handleSignIn = () => {
    if (!isLoginInProgress) {
      setIsLoginInProgress(true);
      (document.activeElement as any).blur();

      authenticationService.login(username, password).subscribe(
        (response: IAuthenticationResponse) => {
          setHasLoginFailed(false);
          setIsLoginInProgress(false);
          storageService.setItem(StorageKeyConstant.USERNAME, username);
          history.replace(appRouteConstants.AUTH.LOGIN_CONFIRMATION);
        },
        error => {
          setHasLoginFailed(true);
          setHasErrorMessage(true);
          setAuthMessage(getErrorMessage(error.code));
          setIsLoginInProgress(false);
        },
      );
    }
  };

  const onVirtualKeyClick = text => {
    const setText = inputToEdit === usernameNav.id ? setUsername : setPassword;
    let inputText = inputToEdit === usernameNav.id ? username : password;
    const ignoreKeys = ['layout1', 'layout2', 'uppercase'];

    if (ignoreKeys.includes(text)) {
      return;
    } else if (text === 'OK') {
      if (inputToEdit === usernameNav.id) {
        if (inputText !== '') {
          focusManager.changeFocus(passwordNav.id);
          setInputToEdit(passwordNav.id);
          onInputClick(passwordNav.id);

          //To move the caret to the focused-styled password field
          const inputElement = document.getElementById('password-input') as any;
          inputElement && inputElement.focus();
        } else {
          focusManager.changeFocus(inputToEdit);
          openKeyboard(false);
          setInputToEdit('');
        }
      } else {
        if (inputText !== '' && username !== '') {
          focusManager.changeFocus(signInButtonNav.id);
          setInputToEdit('');
        } else {
          focusManager.changeFocus(inputToEdit);
          openKeyboard(false);
          setInputToEdit('');
        }
      }

      return;
    } else if (text === 'Clear') {
      inputText = '';
    } else if (text === 'space') {
      inputText += ' ';
    } else if (text === 'delete') {
      inputText = inputText.substring(0, inputText.length - 1);
    } else {
      inputText += text;
    }

    setText(inputText);
    setHasLoginFailed(false);
  };

  return {
    username,
    password,
    isKeyboardOpen,
    inputToEdit,
    areCredentialsEntered,
    hasLoginFailed,
    hasErrorMessage,
    authMessage,
    isLoginInProgress,
    registrationCode,
    history,
    onInputClick,
    handleSignIn,
    onVirtualKeyClick,
  };
};

export const LoginPageComponent = () => {
  const {
    username,
    password,
    isKeyboardOpen,
    inputToEdit,
    areCredentialsEntered,
    hasLoginFailed,
    hasErrorMessage,
    authMessage,
    isLoginInProgress,
    registrationCode,
    history,
    onInputClick,
    handleSignIn,
    onVirtualKeyClick,
  } = useLoginComponent();

  /** Handles the BACK button navigation logic **/
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);

  useEffect(() => {
    if (isMounted.current && !isKeyboardOpen) {
      history.goBack();
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  return (
    <Page nav={pageNav} className="login-page">
      <div className="login-logo-container">
        <div>
          <SiriusXMLogo />
        </div>
      </div>
      <div className="login-content-container">
        <div className="login-frictionless-container">
          {isKeyboardOpen && (
            <div className="login-soft-keyboard-container">
              <Keyboard
                nav={softKeyboardNav}
                onVirtualKeyClick={onVirtualKeyClick}
                isEmail={true}
              />
            </div>
          )}
          {!isKeyboardOpen && (
            <>
              <div className="frictionless-title">
                Activate SiriusXM on your TV
              </div>
              <div className="frictionless-qr-code">
                <img src={SiriusXmTvQRCode} />
              </div>
              <div className="frictionless-instructions">
                <div className="frictionless-instruction-line-1">
                  Scan the QR code with your phone <br />
                  or visit siriusxm.com/tv <br />
                  and enter the activation code:
                </div>
              </div>
              <div className="frictionless-code">{registrationCode}</div>
            </>
          )}
        </div>
        <div
          className={
            isKeyboardOpen ? 'or-divider or-divider-hidden' : 'or-divider'
          }
        >
          <span>or</span>
        </div>
        <div className="login-credentials-container">
          <div className="login-text-container">
            <span>Sign in to SiriusXM</span>
          </div>
          <div className="login-inputs-container">
            <div className="login-inputs-wrapper">
              <TextInput
                nav={usernameNav}
                value={username}
                className={
                  isKeyboardOpen && inputToEdit === usernameNav.id
                    ? 'username text-input-focused'
                    : 'username'
                }
                placeholder="Email/Username"
                onClick={() => onInputClick(usernameNav.id)}
                hasError={hasLoginFailed}
              />
              <TextInput
                id={'password-input'}
                nav={passwordNav}
                value={password}
                className={
                  isKeyboardOpen && inputToEdit === passwordNav.id
                    ? 'password text-input-focused'
                    : 'password'
                }
                isPassword={!(isKeyboardOpen && inputToEdit === passwordNav.id)}
                placeholder="Password"
                onClick={() => onInputClick(passwordNav.id)}
                hasError={hasLoginFailed}
              />
              <RoundedButton
                className={'sign-in-button'}
                nav={
                  areCredentialsEntered
                    ? signInButtonNav
                    : { ...signInButtonNav, skip: true }
                }
                type={'primary'}
                onClick={handleSignIn}
                isActive={!!areCredentialsEntered}
                isLoading={isLoginInProgress}
              >
                Sign In
              </RoundedButton>
            </div>
          </div>
          <div className="login-forgot-password-container">
            <div
              className={`forgot-password-text-container ${
                hasErrorMessage ? 'has-error' : ''
              }`}
            >
              {authMessage}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
