import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { focusManager } from '@accedo/vdkweb-navigation';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import './podcasts-videos-page.component.scss';
import {
  selectAllPodCastCarousel,
  selectAllVideoCarousel,
} from '../../redux/selector/carousel.store';
import { ServiceFactory } from '../../servicelib';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { useLocation } from 'react-router';
import { appRouteConstants } from '../../routing/app.route.constants';
import { PODCASTSVIDEO, MENU } from '../../utils/navigationMap';
import Swimlane from '../../components/swimlane/Swimlane';
import CategoryTile from '../../components/tiles/CategoryTile';
import HeroTile from '../../components/tiles/HeroTile';
import ChannelTile from '../../components/tiles/ChannelTile';
import { useHistory } from 'react-router-dom';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { verticalScroll } from '../../utils/pageUtils';
import { zoneCategory } from '../../components/navigation-menu/navigation-menu-utils';
import { StickyNavbar } from '../../components/sticky-navbar';
import FocusDiv from '../../components/focus-div/FocusDiv';

const { PAGE } = PODCASTSVIDEO;
const { ZONE_MENU } = MENU;

const pageNav = {
  id: PAGE,
  nextup: '',
};

let lastInternalFocusedTile = null;

//TODO: Check if pressing back while on this screen on the X1 returns to the previous page
export const PodcastsVideosPageComponent = props => {
  const { isCoreMenuOpen, setIsCoreMenuOpen } = props;
  const location = useLocation();
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();

  let superCategoryCarousel;
  const superCategory =
    '/' + location.pathname.split('/')[location.pathname.split('/').length - 1];

  const pathQuery = new URLSearchParams(location.search);
  const comingFromSearch = pathQuery.get('comingFromSearch');
  const comingFromSearchRef = useRef(comingFromSearch === 'true');

  const comingFromInternal = pathQuery.get('comingFromInternal');
  const comingFromInternalRef = useRef(comingFromInternal === 'true');

  if (superCategory == appRouteConstants.ALL_PODCASTS) {
    superCategoryCarousel = useSelector(selectAllPodCastCarousel);
  } else if (superCategory == appRouteConstants.ALL_VIDEO) {
    superCategoryCarousel = useSelector(selectAllVideoCarousel);
  }

  const [navIds, setNavIds] = useState({});
  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);

  useEffect(() => {
    if (comingFromSearchRef.current) {
      setIsCoreMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isMounted.current && comingFromSearchRef.current) {
      history.goBack();
    } else if (
      isMounted.current &&
      Object.keys(navIds).some(key =>
        focusManager.getCurrentFocus().includes(key),
      )
    ) {
      focusManager.changeFocus(ZONE_MENU);
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[pageNav.id] && history.action === 'POP') {
      focusManager.changeFocus(lastFocusedTile[pageNav.id]);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  useEffect(() => {
    lastInternalFocusedTile = null;
    window.scroll(0, 0);

    if (superCategory == appRouteConstants.ALL_PODCASTS) {
      carouselStoreService.selectAllPodCastsCarousels();
    } else if (superCategory == appRouteConstants.ALL_VIDEO) {
      carouselStoreService.selectAllVideoCarousels();
    }

    if (isMounted.current && history.action !== 'POP') {
      if (focusManager.isChildFocused(pageNav.id)) {
        const superCategoryToFocus = location.pathname.split('/')[2];
        const superCategoryButton = zoneCategory.discoverZone.buttons.find(
          button => button.superCategory === superCategoryToFocus,
        );
        focusManager.changeFocus(superCategoryButton.id);
      }
    }
  }, [location.pathname]);

  /* Determines if the Mini Player was opened, so that the focus does not go back to a tile if the Player was not opened through it */

  // useEffect(() => {
  //   return () => {
  //     if (focusManager.isChildFocused(pageNav.id)) {
  //       dispatch(
  //         saveLastFocusedTileId({
  //           ...lastFocusedTile,
  //           [pageNav.id]: lastInternalFocusedTile,
  //         }),
  //       );
  //     } else {
  //       dispatch(
  //         saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
  //       );
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (superCategoryCarousel && superCategoryCarousel.zone) {
      const ids = [];
      superCategoryCarousel.zone.forEach(zone => {
        zone.hero.forEach(heroCarousel => {
          ids.push(heroCarousel.guid);
        });

        if (zone.subCategories.length) {
          ids.push(zone.zoneGuid);
        }

        zone.content.forEach(contentCarousel => {
          ids.push(contentCarousel.guid);
        });
      });

      const navs = {};
      ids.forEach((id, index) => {
        navs[id] = {
          id: id,
          nextup:
            ids[index - 1] ||
            (comingFromSearchRef.current ? undefined : pageNav.nextup),
          nextdown: ids[index + 1],
          useLastFocus: true,
          parent: pageNav.id,
        };
      });

      if (comingFromInternalRef.current || comingFromSearchRef.current) {
        if (!focusManager.isChildFocused(pageNav.id)) {
          focusManager.changeFocus(`${ids[0]}-ITEM-0`);
        }
      }

      if (comingFromSearchRef.current) {
        pageNav.nextup = '';
      } else {
        pageNav.nextup = ZONE_MENU;
      }

      navs['page'] = {
        ...pageNav,
      };

      setNavIds(navs);
    }
  }, [superCategoryCarousel]);

  return (
    <FocusDiv
      className="podcasts-videos-page"
      nav={navIds['page']}
      onFocus={() => {
        if (lastInternalFocusedTile) {
          focusManager.changeFocus(lastInternalFocusedTile);
        } else if (!lastFocusedTile[pageNav.id]) {
          const [firstCarouselId] = Object.keys(navIds);
          focusManager.changeFocus(firstCarouselId);
        } else if (lastFocusedTile[pageNav.id]) {
          focusManager.changeFocus(lastFocusedTile[pageNav.id]);
        }
      }}
    >
      {location.pathname.includes('all_video') &&
        comingFromSearchRef.current && (
          <StickyNavbar isCoreMenuOpen={isCoreMenuOpen} children="Videos" />
        )}
      {superCategoryCarousel &&
        superCategoryCarousel.zone &&
        superCategoryCarousel.zone.map(zone => {
          return (
            <Fragment key={zone.zoneId}>
              {zone.hero &&
                zone.hero.map(heroCarousel => {
                  return (
                    <Swimlane
                      pageId={pageNav.id}
                      key={heroCarousel.guid}
                      nav={navIds[heroCarousel.guid]}
                      className={'home-hero-carousel-wrapper'}
                      headerClassName={'carousel-categories-header-top'}
                      itemWrapperClassName={'hero-wrapper'}
                      data={heroCarousel.tiles}
                      component={HeroTile}
                      displayText={zone.zoneTitle?.toUpperCase()}
                      animation={animation}
                      onFocus={id => {
                        if (!animation) {
                          verticalScroll(heroCarousel.guid);
                        }
                        lastInternalFocusedTile = id;
                      }}
                    />
                  );
                })}
              {zone.subCategories && zone.subCategories.length > 0 && (
                <Swimlane
                  pageId={pageNav.id}
                  key={zone.zoneGuid}
                  nav={navIds[zone.zoneGuid]}
                  className={'home-carousel-wrapper'}
                  headerClassName={'carousel-categories-header-top'}
                  itemWrapperClassName={'category-wrapper'}
                  displayText={superCategoryCarousel?.pageTitle?.textValue}
                  data={zone.subCategories}
                  component={CategoryTile}
                  animation={animation}
                  onFocus={id => {
                    if (!animation) {
                      verticalScroll(zone.zoneGuid);
                    }
                    lastInternalFocusedTile = id;
                  }}
                />
              )}
              {zone.content &&
                zone.content.map(contentCarousel => {
                  return (
                    zone.content && (
                      <Swimlane
                        pageId={pageNav.id}
                        key={contentCarousel.guid}
                        nav={navIds[contentCarousel.guid]}
                        className={'home-hero-carousel-wrapper'}
                        headerClassName={'carousel-categories-header-top'}
                        itemWrapperClassName={'channel-wrapper'}
                        data={contentCarousel.tiles}
                        component={ChannelTile}
                        displayText={contentCarousel.title?.textValue}
                        animation={animation}
                        onFocus={id => {
                          if (!animation) {
                            verticalScroll(contentCarousel.guid);
                          }
                          lastInternalFocusedTile = id;
                        }}
                      />
                    )
                  );
                })}
            </Fragment>
          );
        })}
    </FocusDiv>
  );
};
