import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  ServiceFactory,
  DmcaService,
  SkipService,
  LiveTimeService,
  MediaPlayerService,
  PlayheadTimestampService,
} from '../../servicelib';
import { SkipButtonService } from '../../sxmservicelayer/skip-button/skip-button.service';
import {
  getFFWKeyPressedId,
  getRewindKeyPressedId,
} from '../../redux/selector/xdk.store';
import {
  selectNowPlayingState,
  getIsLive,
} from '../../redux/selector/now-playing.store';
import { useObservable } from '../../hooks';

declare const $badger: any;
const CONTENT_STATES = {
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  SKIP_FORWARD_15: 'SKIP_FORWARD_15',
  SKIP_BACKWARD_15: 'SKIP_BACKWARD_15',
  SKIP_NEXT: 'SKIP_NEXT',
  SKIP_PREVIOUS: 'SKIP_PREVIOUS',
  SKIP_TO_SEGMENT: 'SKIP_TO_SEGMENT',
};

const SkippingTracker = ({ showUpNext }) => {
  /***** Handles the FFW key press logic for skipping - START *****/

  const isMounted = useRef(false);
  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;
  const dmcaService = ServiceFactory.getInstance(DmcaService) as DmcaService;
  const skipService = ServiceFactory.getInstance(SkipService) as SkipService;
  const skipButtonService = ServiceFactory.getInstance(
    SkipButtonService,
  ) as SkipButtonService;
  const liveTimeService = ServiceFactory.getInstance(
    LiveTimeService,
  ) as LiveTimeService;
  const playheadTimestampService = ServiceFactory.getInstance(
    PlayheadTimestampService,
  ) as PlayheadTimestampService;

  const liveTime = useObservable(liveTimeService.liveTime$) as any;
  const playheadTimestampForLive = useObservable(
    playheadTimestampService.playhead,
  );

  const nowInMs = moment().valueOf();
  const liveDeltaInSeconds =
    (nowInMs - (liveTime ? liveTime.zuluMilliseconds : 0)) / 1000;
  const livePositionInSeconds =
    mediaPlayerService?.mediaPlayer?.videoPlayer?.videoElement?.duration ||
    18450;
  const livePointToSeekTo = livePositionInSeconds - liveDeltaInSeconds;

  const isPlayheadBehindLive = () => {
    const delta =
      livePointToSeekTo -
      ((playheadTimestampForLive as any)?.currentTime.seconds || 0);
    return delta > 15;
  };

  const nowPlayingStore = useSelector(selectNowPlayingState);
  skipButtonService.nowPlayingStore = nowPlayingStore;
  const isLive = useSelector(getIsLive);
  const isDisallowed = dmcaService.isDisallowed(nowPlayingStore);
  //const isRestricted = dmcaService.isRestricted(nowPlayingStore);
  const isLiveAndDisallowed = isDisallowed && isLive;
  const isSkipForwardDisabled =
    !skipButtonService.skipButtonsState.forward ||
    (isLive && !isPlayheadBehindLive()) ||
    isLiveAndDisallowed;

  const onSkipForward = nowPlayingStore => {
    const dcmaInfo = {
      mediaType: nowPlayingStore.mediaType,
      dmcaInfo: nowPlayingStore.dmcaInfo,
      mediaId: nowPlayingStore.mediaId,
    };
    skipService.skipForward(
      dcmaInfo,
      livePointToSeekTo, //Will only be used for live channels
    );
    skipButtonService.onSkip(skipButtonService.skipType.FORWARD);
    $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
      contentState: CONTENT_STATES.SKIP_NEXT,
    });
  };

  const ffwId = useSelector(getFFWKeyPressedId);
  useEffect(() => {
    if (isMounted.current && !showUpNext) {
      if (!isSkipForwardDisabled) {
        onSkipForward(nowPlayingStore);
      }
    }
  }, [ffwId]);

  /***** Handles the FFW key press logic for skipping - END *****/

  /***** Handles the REWIND key press logic for skipping - START *****/
  const isSkipBackwardDisabled =
    !skipButtonService.skipButtonsState.back || isLiveAndDisallowed;

  const onSkipBack = nowPlayingStore => {
    const dcmaInfo = {
      mediaType: nowPlayingStore.mediaType,
      dmcaInfo: nowPlayingStore.dmcaInfo,
      mediaId: nowPlayingStore.mediaId,
    };
    skipService.skipBack(dcmaInfo, livePointToSeekTo);
    skipButtonService.onSkip(skipButtonService.skipType.BACKWARD);
    $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
      contentState: CONTENT_STATES.SKIP_PREVIOUS,
    });
  };

  const rewindId = useSelector(getRewindKeyPressedId);
  useEffect(() => {
    if (isMounted.current && !showUpNext) {
      if (!isSkipBackwardDisabled) {
        onSkipBack(nowPlayingStore);
      }
    } else {
      isMounted.current = true;
    }
  }, [rewindId]);

  /***** Handles the REWIND key press logic for skipping - END *****/

  return <div></div>;
};

export default SkippingTracker;
