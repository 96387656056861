import * as _ from 'lodash';
import { IAppConfig } from '../../config/interfaces/app-config.interface';
import { IRelativeUrlConfig } from '../../config/interfaces/config.interface';
import { ConfigService } from '../../config/config.service';
import {
  ICdnAccessToken,
  ResponseInterceptor,
} from '../../http/http.provider.response.interceptor';
import { Logger } from '../../logger/logger';
import {
  IHlsManifestFile,
  IMediaEndPoint,
  IRelativeUrlSetting,
  MediaTimeLine,
} from '../../../index';
import { IProviderDescriptor } from '../../service/provider.descriptor.interface';
import { SettingsService } from '../../settings/settings.service';
import { MediaUtil } from '../media.util';
import { addProvider } from '../../index';
import { PLAYER_CONFIG as playerConfig } from './video-player.config';
import { VideoPlayerConstants } from './video-player.consts';
import { BehaviorSubject } from 'rxjs';
import { getCurrentUrlQueryParams } from '../../util/utilities';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/12/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Creational factory (albeit without a static method) to acquire an video player configuration object.
 */
export class VideoPlayerConfigFactory {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('VideoPlayerConfigFactory');

  /**
   * cdnAccessToken assigned from responseInterceptor.cdnAccessTokens Observable.
   * And used to send token information to web video player.
   */
  private cdnAccessTokens: ICdnAccessToken;

  /**
   * Reflects the current media timeline.
   * @type {MediaTimeLine}
   */
  private mediaTimeLine: MediaTimeLine;

  public factoryReady$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public livePrimaryHostname: string;
  public mediaHostnames: any;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(VideoPlayerConfigFactory, VideoPlayerConfigFactory, [
      ConfigService,
      SettingsService,
      ResponseInterceptor,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor
   * @param {ConfigService} configService
   * @param {SettingsService} settingsService
   * @param {ResponseInterceptor} responseInterceptor
   * @param {IAppConfig} SERVICE_CONFIG
   */
  constructor(
    private configService: ConfigService,
    private settingsService: SettingsService,
    private responseInterceptor: ResponseInterceptor,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    VideoPlayerConfigFactory.logger.debug('Constructor()');

    this.observeCdnAccessTokens();
    this.observeRelativeUrlConfiguration();
  }

  /**
   * Returns the media player service for the given type of media requested.
   */
  public getConfig(): any {
    const params: any = getCurrentUrlQueryParams();
    playerConfig.debug = params.debug === 'true';
    return playerConfig;
  }

  /**
   * determine auto playback.
   * @returns {boolean}
   */
  public getAutoPlay(): boolean {
    return (
      !this.mediaTimeLine.isDataComeFromResume ||
      this.mediaTimeLine.isDataComeFromResumeWithDeepLink
    );
  }

  /**
   * Used to get playback type based on Live or On Demand video (ala AOD).
   * @returns {string}
   */
  public getPlaybackType(): string {
    if (MediaUtil.isVideoMediaTypeLive(this.mediaTimeLine.mediaType)) {
      return VideoPlayerConstants.LIVE;
    } else if (
      MediaUtil.isVideoMediaTypeOnDemand(this.mediaTimeLine.mediaType)
    ) {
      return VideoPlayerConstants.VOD;
    }

    return '';
  }

  /**
   * Returns an HLS URL for a given origin server endpoint name and manifest size.
   *
   * @param {string} endPointName
   * @param {string} size
   * @returns {string}
   */
  public getVideoUrl(endPointName: string, size: string): string {
    const url: string = '';
    const mediaEndPoint: IMediaEndPoint = _.find(
      this.mediaTimeLine.mediaEndPoints,
      findMediaEndPoint(endPointName),
    ) as IMediaEndPoint;
    const useManifests: boolean =
      mediaEndPoint.manifestFiles && mediaEndPoint.manifestFiles.length > 0;

    if (useManifests) {
      const manifest: IHlsManifestFile = _.find(
        mediaEndPoint.manifestFiles,
        findManifest(size),
      ) as IHlsManifestFile;
      return manifest ? manifest.url : url;
    } else {
      return mediaEndPoint.url;
    }

    function findMediaEndPoint(endPointName: string) {
      return function(mediaEndPoint: IMediaEndPoint): boolean {
        return mediaEndPoint.name.toLowerCase() === endPointName.toLowerCase();
      };
    }

    function findManifest(size: string) {
      return function(manifest: IHlsManifestFile): boolean {
        return manifest.size.toLowerCase() === size.toLowerCase();
      };
    }
  }

  /**
   * Returns the default HLS URL for video.
   * @returns {string}
   */
  private getDefaultUrl(): string {
    return '';
  }

  /**
   * Used to create and get Video Urls Object which can be fed into config object.
   * @returns {any}
   */
  private getVideoUrls(): Array<any> {
    return [];
  }

  /**
   * Used to normalize configService.relativeUrlConfiguration to this.
   * @param {IRelativeUrlConfig} relativeUrlConfig
   */
  private setRelativeUrlValues(relativeUrlConfig: IRelativeUrlConfig) {
    if (relativeUrlConfig) {
      VideoPlayerConfigFactory.logger.debug('setRelativeUrlValues()');

      const livePrimaryHostSetting: IRelativeUrlSetting = _.find(
        relativeUrlConfig.settings,
        { name: VideoPlayerConstants.LIVE_PRIMARY_HOST },
      );
      this.livePrimaryHostname = livePrimaryHostSetting.url;

      relativeUrlConfig.settings.forEach(setting => {
        //TODO: Remove this hardcoing once the config response returns the appropriate relatievUrls
        this.mediaHostnames = {
          ...this.mediaHostnames,
          [`%${setting.name}%`]: setting.url
            .replace('hlspproduction2c', 'hlspproduction2e')
            .replace('hlspproduction2d', 'hlspproduction2e'),
        };
      });
      this.factoryReady$.next(true);
    }
  }

  /**
   * Observe data changes on the CDN access tokens. If there's changes, update the them.
   */
  private observeCdnAccessTokens() {
    VideoPlayerConfigFactory.logger.debug('observeCdnAccessTokens()');

    this.responseInterceptor.cdnAccessTokens.subscribe(
      onCdnAccessTokensSuccess.bind(this),
      onCdnAccessTokensFault.bind(this),
    );

    /**
     * Updates the CN Access tokens.
     * @param {ICdnAccessToken} cdnAccessTokens
     */
    function onCdnAccessTokensSuccess(cdnAccessTokens: ICdnAccessToken) {
      if (cdnAccessTokens) {
        VideoPlayerConfigFactory.logger.debug(
          'onCdnAccessTokensSuccess( Update tokens. )',
        );
        this.cdnAccessTokens = cdnAccessTokens;
      }
    }

    /**
     * If observable throws a fault then logs the error message.
     * @param error
     */
    function onCdnAccessTokensFault(/*error: any*/) {
      VideoPlayerConfigFactory.logger.warn(
        'onCdnAccessTokensFault( Error: ${JSON.stringify(error)}. )',
      );
    }
  }

  /**
   * Observe data changes on the URL config. If there's changes, update the URLs.
   */
  private observeRelativeUrlConfiguration() {
    VideoPlayerConfigFactory.logger.debug('observeRelativeUrlConfiguration()');

    this.configService.relativeUrlConfiguration.subscribe(
      onRelativeUrlConfigurationSuccess.bind(this),
      onRelativeUrlConfigurationFault.bind(this),
    );

    /**
     * Updates the relative URLs.
     * @param {IRelativeUrlConfig} relativeUrlConfig
     */
    function onRelativeUrlConfigurationSuccess(
      relativeUrlConfig: IRelativeUrlConfig,
    ) {
      VideoPlayerConfigFactory.logger.debug(
        'onRelativeUrlConfigurationSuccess()',
      );
      this.setRelativeUrlValues(relativeUrlConfig);
    }

    /**
     * If observable throws a fault then logs the error message.
     * @param error
     */
    function onRelativeUrlConfigurationFault(/*error: any*/) {
      VideoPlayerConfigFactory.logger.warn(
        'onRelativeUrlConfigurationFault( Error {error} for relative URL config observable. )',
      );
    }
  }
}
