import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as PlayIcon } from '../../assets/images/play.svg';
import { ReactComponent as SkipForward15Seconds } from '../../assets/images/forward-15.svg';
import { ReactComponent as SkipBackward15Seconds } from '../../assets/images/back-15.svg';
import spinnerUrl from '../../assets/images/buffering-blue@3x.png';
import { ServiceFactory, VideoPlayerService } from '../../servicelib';
import './video.component.scss';

const VIDEO_WRAPPER_ID = 'video-wrapper';
const overlayHideTimeout = { id: undefined, DELAY: 5000 };
const previousPlaybackState = { playbackReadyState: undefined };
declare const $badger: any;

const Video = props => {
  const {
    channelLogoForVod,
    channelName,
    showLongTitle,
    episodeLongTitle,
    emissionDay,
    abbreviatedDuration,
    showSegmentViewer,
    showEdp,
    showRelatedContent,
    showSkipIcon,
    isPaused,
    SKIP_FORWARD_15_SECONDS_ICON,
    SKIP_BACKWARD_15_SECONDS_ICON,
    getElapsedTime,
    getProgress,
    getDuration,
    playheadTimestamp,
    CONTENT_STATES,
  } = props;
  const videoPlayerService = ServiceFactory.getInstance(
    VideoPlayerService,
  ) as VideoPlayerService;
  //const videoRef = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    const videoWrapper = document.getElementById(VIDEO_WRAPPER_ID);
    videoWrapper.style.visibility = 'visible';
    videoWrapper.style.width = '120rem';
    videoWrapper.style.height = '67.5rem';
    const videoElement = videoWrapper.firstChild as any;
    videoElement.style.visibility = 'visible';
    videoElement.style.width = '120rem';
    videoElement.style.height = '67.5rem';

    return () => {
      videoWrapper.style.visibility = null;
      videoWrapper.style.width = null;
      videoWrapper.style.height = null;
      videoWrapper.style.background = null;
      videoElement.style.visibility = null;
      videoElement.style.width = null;
      videoElement.style.height = null;

      $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
        contentState: CONTENT_STATES.STOPPED,
      });
      videoPlayerService.pause();
      videoPlayerService.stop();
    };
  }, []);

  const [showSpinner, setShowSpinner] = useState(true);
  useEffect(() => {
    const subscription = videoPlayerService.playbackReady$.subscribe(
      isPlaybackReady => {
        //All this logic avoids the player from getting stuck when returning to a video from the Mini Player
        if (isPlaybackReady === null) {
          previousPlaybackState.playbackReadyState = 'LOADING';
        } else if (isPlaybackReady) {
          if (previousPlaybackState.playbackReadyState === 'LOADING') {
            if (!isPlaybackReady.willAutoplay) {
              videoPlayerService.play();
            }
            $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
              contentState: CONTENT_STATES.PLAYING,
            });

            setShowSpinner(false);
          } else {
            previousPlaybackState.playbackReadyState = 'LOADING';
          }
        }
      },
    );

    return () => {
      previousPlaybackState.playbackReadyState = undefined;
      subscription.unsubscribe();
    };
  }, []);

  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    if (isMounted && isMounted.current) {
      if (isPaused) {
        clearTimeout(overlayHideTimeout.id);
        setShowOverlay(true);
      } else {
        overlayHideTimeout.id = setTimeout(() => {
          setShowOverlay(false);
        }, overlayHideTimeout.DELAY);
      }
    }

    return () => clearTimeout(overlayHideTimeout.id);
  }, [isPaused]);

  return (
    <div className="now-playing-video-container">
      {showSpinner && (
        <div
          className="video-spinner"
          style={{ backgroundImage: `url("${spinnerUrl}")` }}
        />
      )}
      {!showEdp && !showRelatedContent && !showSegmentViewer && (
        <>
          <div
            className={`now-playing-video-metadata-container ${
              showOverlay ? 'is-paused' : ''
            }`}
          >
            <div
              className="now-playing-video-show-logo"
              style={{
                backgroundImage: `url("${channelLogoForVod}?&width=180&height=144&preserveAspect=true")`,
              }}
            />
            <div className="now-playing-video-media-info-container">
              <div className="now-playing-video-channel-info">
                <span className="now-playing-video-channel">
                  {`${channelName ? channelName + ' - On Demand' : ''} `}
                </span>
                {showLongTitle && <span className="dot-icon" />}
                <span className="now-playing-video-show">
                  {showLongTitle || ''}
                </span>
              </div>
              <div className="now-playing-video-episode-title">
                {episodeLongTitle || ''}
              </div>
              <div className="now-playing-video-emission-info">
                <span className="now-playing-video-emission-day">
                  {emissionDay || ''}
                </span>
                {emissionDay && <span className="dot-icon" />}
                <span className="now-playing-video-duration">
                  {abbreviatedDuration || ''}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`now-playing-video-player-controls-container ${
              showOverlay ? 'is-paused' : ''
            }`}
          >
            <div
              className={`video-playback-icon-container ${
                isPaused || showSkipIcon.icon ? 'loaded' : ''
              }`}
            >
              <PlayIcon style={{ display: isPaused ? 'block' : 'none' }} />
              <SkipForward15Seconds
                style={{
                  display:
                    showSkipIcon.icon === SKIP_FORWARD_15_SECONDS_ICON
                      ? 'block'
                      : 'none',
                }}
              />
              <SkipBackward15Seconds
                style={{
                  display:
                    showSkipIcon.icon === SKIP_BACKWARD_15_SECONDS_ICON
                      ? 'block'
                      : 'none',
                }}
              />
            </div>
            <div className="video-progress-bar-container">
              <div className="video-current-time">{getElapsedTime()}</div>
              <div className="video-progress-bar">
                <div className="video-progress-bar-background" />
                <div
                  className="video-current-listening-position"
                  style={{
                    width: `${getProgress(playheadTimestamp)}%`,
                  }}
                />
              </div>
              <div className="video-end-time">{getDuration()}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Video;
