import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Page } from '@accedo/vdkweb-tv-ui';
import {
  selectNowPlayingState,
  getChannel,
  getChannelLogoUrl,
  getAlbumImage,
  getArtistName,
  getTrackName,
  getEpisode,
  getIsOnDemand,
  getIsLive,
  getIsSeededRadio,
  getShow,
  getIsAod,
  getIsVod,
  getBackgroundImage,
  getShowBackgroundImage,
  getIsAIC,
  getIsVideo,
  getPosterImageUrl,
  getIsIrisPodcast,
} from '../../redux/selector/now-playing.store';
import { selectCarouselState } from '../../redux/selector/carousel.store';
import {
  getPageBackId,
  getPageRightId,
  getPageLeftId,
  getFFWKeyPressedId,
  getRewindKeyPressedId,
} from '../../redux/selector/xdk.store';
import { selectChannelLogoUrl as selectChannelLogoUrlAction } from '../../redux/action/now-playing.action';
import {
  ServiceFactory,
  MediaPlayerService,
  MediaTimestampService,
  MediaUtil,
  DmcaService,
  SkipService,
  NAME_COLOR_CHANNEL_LOGO,
  //SeekService,
  StorageService,
  StorageKeyConstant,
  TILE_CHANNEL_BACKGROUND,
  NAME_BACKGROUND,
  PlayheadTimestampService,
  LiveTimeService,
  MediaTimeLineService,
  findMediaItemByTimestamp,
} from '../../servicelib';
import { NowPlayingStoreService } from '../../sxmservicelayer/now-playing/now-playing.store.service';
import { SkipButtonService } from '../../sxmservicelayer/skip-button/skip-button.service';
import { useObservable } from '../../hooks';
import { ReactComponent as PlayIcon } from '../../assets/images/play.svg';
import { ReactComponent as SkipForward } from '../../assets/images/skip-forward-white.svg';
import { ReactComponent as SkipBackward } from '../../assets/images/skip-back-white.svg';
import { ReactComponent as Chevron } from '../../assets/images/chevron-left.svg';
import { ReactComponent as SkipForward15Seconds } from '../../assets/images/forward-15.svg';
import { ReactComponent as SkipBackward15Seconds } from '../../assets/images/back-15.svg';
import EDPInformationDrawer from '../../components/edp/edp.component';
import SegmentViewer from '../../components/segment-viewer/segment-viewer.component';
import RelatedContent from '../../components/related-content/related-content.component';
import Video from '../../components/video/video.component';
import './now-playing.component.scss';
import moment from 'moment';
import { focusManager } from '@accedo/vdkweb-navigation';

const nowPlayingNav = {
  id: 'now-playing',
  nextdown: 'edp-information-drawer',
  nextup: 'related-content',
};
const edpInformationDrawerNav = {
  id: 'edp-information-drawer',
};
const relatedContentNav = {
  id: 'related-content',
};

//const LIVE_POINT_FACTOR: number = 5; //Constant used to disable forward skip for what's considered "live" so a user can't spam forward skip
//const SECONDS_BEHIND_LIVEPOINT_CONSIDERED_LIVE = 14;
const SKIP_FORWARD_ICON = 'SKIP_FORWARD_ICON';
const SKIP_BACKWARD_ICON = 'SKIP_BACKWARD_ICON';
const SKIP_FORWARD_15_SECONDS_ICON = 'SKIP_FORWARD_15_SECONDS_ICON';
const SKIP_BACKWARD_15_SECONDS_ICON = 'SKIP_BACKWARD_15_SECONDS_ICON';
const skipIconTimeout = { id: undefined };

const CONTENT_STATES = {
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  SKIP_FORWARD_15: 'SKIP_FORWARD_15',
  SKIP_BACKWARD_15: 'SKIP_BACKWARD_15',
  SKIP_NEXT: 'SKIP_NEXT',
  SKIP_PREVIOUS: 'SKIP_PREVIOUS',
  SKIP_TO_SEGMENT: 'SKIP_TO_SEGMENT',
};

const ZERO_TIME = '0:00';

export const getTime = (value, showLeadingZeroes = true) => {
  const isNumber: boolean = !isNaN(value);
  const isValidValue: boolean = value >= 1;

  // Never show the user NaN.
  // Never allow values less than the minimum threshold to be considered a valid value.
  // For example, we might get passed -1 as audio/video is loaded/unloaded format and
  // exit early since we should never display a negative number.
  if (!isNumber || !isValidValue) {
    return ZERO_TIME;
  }

  let hours: string = String(Math.floor(value / 3600));
  let minutes: string = String(Math.floor(value / 60) % 60);
  let seconds: string = String(Math.floor(value) % 60);

  // seconds should always be two digits
  if (Number(seconds) < 10) {
    seconds = '0' + seconds;
  }

  // for lengths above an hour, minutes should always be two digits
  if (Number(minutes) < 10) {
    minutes = '0' + minutes;
  }

  if (showLeadingZeroes && Number(hours) < 10) {
    hours = '0' + hours + ':';
  } else if (!showLeadingZeroes) {
    if (Number(hours) < 1) {
      hours = '';
    } else {
      hours = '0' + hours + ':';
    }
  }

  return `${hours}${minutes}:${seconds}`;
};

declare const $badger: any;

export const NowPlayingPageComponent = ({ setShowUpNext, showUpNext }) => {
  const nowPlayingStoreService = ServiceFactory.getInstance(
    NowPlayingStoreService,
  ) as NowPlayingStoreService;
  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;

  const [hasPlaybackStateChanged, setHasPlaybackStateChanged] = useState(false);
  const isPaused = mediaPlayerService.isPaused();

  const togglePlayPause = () => {
    $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
      contentState: mediaPlayerService.isPaused()
        ? CONTENT_STATES.PLAYING
        : CONTENT_STATES.PAUSED,
    });
    mediaPlayerService.mediaPlayer.togglePausePlay().subscribe();
    setHasPlaybackStateChanged(!hasPlaybackStateChanged);
    setShowSkipIcon({ icon: '' });
  };

  const isVideo = useSelector(getIsVideo);
  const isAIC = useSelector(getIsAIC);
  const isSeededRadio = useSelector(getIsSeededRadio);
  useEffect(() => {
    if (
      (!isVideo && mediaPlayerService.isPaused()) ||
      (isAIC && mediaPlayerService.mediaPlayer.isFinished()) ||
      (isSeededRadio && mediaPlayerService.mediaPlayer.isStopped())
    ) {
      togglePlayPause();
    }
  }, []);

  const [showEdp, setShowEdp] = useState(false);
  const [showSegmentViewer, setShowSegmentViewer] = useState(false);
  const [showRelatedContent, setShowRelatedContent] = useState(false);
  const channel = useSelector(getChannel);
  const show = useSelector(getShow);
  const isAod = useSelector(getIsAod);
  const isVod = useSelector(getIsVod);
  const dispatch = useDispatch();

  useEffect(() => {
    const url = nowPlayingStoreService.setChannelLogoImageSrc(
      channel,
      show,
      isAod,
      isVod,
    );

    dispatch(selectChannelLogoUrlAction(url));
  }, [channel, show, isAod, isVod]);

  const backgroundImageRef = useRef(null);
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);

  useEffect(() => {
    if (backgroundImageRef && backgroundImageRef.current) {
      backgroundImageRef.current.onload = () => {
        setIsBackgroundLoaded(true);
      };
    }

    return () => {
      if (backgroundImageRef.current && backgroundImageRef.current.onload) {
        backgroundImageRef.current.onload = undefined;
      }
    };
  }, [backgroundImageRef.current]);

  const logoImageRef = useRef(null);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  useEffect(() => {
    if (logoImageRef && logoImageRef.current) {
      logoImageRef.current.onload = () => {
        setIsLogoLoaded(true);
      };
    }

    return () => {
      if (logoImageRef.current && logoImageRef.current.onload) {
        logoImageRef.current.onload = undefined;
      }
    };
  }, [logoImageRef.current]);

  const artistRadioImageRef = useRef(null);
  const [isArtistRadioImagLoaded, setIsArtistRadioImagLoaded] = useState(false);
  useEffect(() => {
    if (artistRadioImageRef && artistRadioImageRef.current) {
      artistRadioImageRef.current.onload = () => {
        setIsArtistRadioImagLoaded(true);
      };
    }

    return () => {
      if (artistRadioImageRef.current && artistRadioImageRef.current.onload) {
        artistRadioImageRef.current.onload = undefined;
      }
    };
  }, [artistRadioImageRef.current]);

  /***** Handles the BACK button navigation logic - START *****/
  const isMounted = useRef(false);
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useEffect(() => {
    if (isMounted.current) {
      if (showEdp) {
        setShowEdp(false);
        focusManager.changeFocus(nowPlayingNav.id);
      } else if (showSegmentViewer) {
        setShowSegmentViewer(false);
        focusManager.changeFocus(nowPlayingNav.id);
      } else if (showRelatedContent) {
        setShowRelatedContent(false);
        focusManager.changeFocus(nowPlayingNav.id);
      } else if (showUpNext) {
        setShowUpNext(false);
        history.goBack();
      } else {
        history.goBack();
      }
    }
  }, [backId]);
  /***** Handles the BACK button navigation logic - END *****/

  /***** Handles the RIGHT key press logic for skipping - START *****/

  const dmcaService = ServiceFactory.getInstance(DmcaService) as DmcaService;
  const skipService = ServiceFactory.getInstance(SkipService) as SkipService;
  const mediaTimestampService = ServiceFactory.getInstance(
    MediaTimestampService,
  ) as MediaTimestampService;
  const skipButtonService = ServiceFactory.getInstance(
    SkipButtonService,
  ) as SkipButtonService;
  //const seekService = ServiceFactory.getInstance(SeekService) as SeekService;
  const liveTimeService = ServiceFactory.getInstance(
    LiveTimeService,
  ) as LiveTimeService;
  const playheadTimestampService = ServiceFactory.getInstance(
    PlayheadTimestampService,
  ) as PlayheadTimestampService;

  const liveTime = useObservable(liveTimeService.liveTime$) as any;
  const playheadTimestamp = useObservable(
    mediaTimestampService.playheadTimestamp$,
  );
  const playheadTimestampForLive = useObservable(
    playheadTimestampService.playhead,
  );

  const nowInMs = moment().valueOf();
  const liveDeltaInSeconds =
    (nowInMs - (liveTime ? liveTime.zuluMilliseconds : 0)) / 1000;
  const livePositionInSeconds =
    mediaPlayerService?.mediaPlayer?.videoPlayer?.videoElement?.duration ||
    18450;
  const livePointToSeekTo = livePositionInSeconds - liveDeltaInSeconds;

  const isPlayheadBehindLive = () => {
    const delta =
      livePointToSeekTo -
      ((playheadTimestampForLive as any)?.currentTime.seconds || 0);
    return delta > 15;
  };

  const nowPlayingStore = useSelector(selectNowPlayingState);
  skipButtonService.nowPlayingStore = nowPlayingStore;
  const isLive = useSelector(getIsLive);
  const isDisallowed = dmcaService.isDisallowed(nowPlayingStore);
  const isRestricted = dmcaService.isRestricted(nowPlayingStore);
  const isLiveAndDisallowed = isDisallowed && isLive;
  const isSkipForwardDisabled =
    !skipButtonService.skipButtonsState.forward ||
    (isLive && !isPlayheadBehindLive()) ||
    isLiveAndDisallowed;

  const onSkipForward = nowPlayingStore => {
    const dcmaInfo = {
      mediaType: nowPlayingStore.mediaType,
      dmcaInfo: nowPlayingStore.dmcaInfo,
      mediaId: nowPlayingStore.mediaId,
    };
    skipService.skipForward(
      dcmaInfo,
      livePointToSeekTo, //Will only be used for live channels
    );
    skipButtonService.onSkip(skipButtonService.skipType.FORWARD);
    clearSkipIconTimeout();
    setShowSkipIcon({ icon: SKIP_FORWARD_ICON });
    skipIconTimeout.id = setTimeout(() => setShowSkipIcon({ icon: '' }), 1000);
    $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
      contentState: CONTENT_STATES.SKIP_NEXT,
    });
  };

  //TODO: Uncomment once +/-15 seconds is ready to be implemented for Podcasts
  /*
  let isSkipForward15SecondsDisabled = true;

  if (isLive) {
    isSkipForward15SecondsDisabled =
      isDisallowed ||
      isRestricted ||
      livePointToSeekTo -
        ((playheadTimestampForLive as any)?.currentTime.seconds || 0) <=
        SECONDS_BEHIND_LIVEPOINT_CONSIDERED_LIVE;
  } else {
    //For AOD, mediaTimestampService.livePointTimestamp will be the actual length of the media.
    //mediaTimestampService.playheadTimestamp will represent the actual playhead position
    isSkipForward15SecondsDisabled =
      isDisallowed ||
      isRestricted ||
      mediaTimestampService.livePointTimestamp -
        mediaTimestampService.playheadTimestamp <=
        SECONDS_BEHIND_LIVEPOINT_CONSIDERED_LIVE;
  }

  const onSkipForward15 = () => {
    let seconds = 0;

    if (isLive) {
      seconds =
        ((playheadTimestampForLive as any)?.currentTime.seconds || 0) + 15 <
        livePointToSeekTo
          ? ((playheadTimestampForLive as any)?.currentTime.seconds || 0) + 15
          : livePointToSeekTo;
    } else {
      seconds =
        mediaTimestampService.playheadTimestamp + 15 <
        mediaTimestampService.durationTimestamp
          ? mediaTimestampService.playheadTimestamp + 15
          : mediaTimestampService.durationTimestamp;
    }

    seekService.seekThenPlay(seconds, false);
    clearSkipIconTimeout();
    setShowSkipIcon({ icon: SKIP_FORWARD_15_SECONDS_ICON });
    skipIconTimeout.id = setTimeout(() => setShowSkipIcon({ icon: '' }), 1000);
    $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
      contentState: CONTENT_STATES.SKIP_FORWARD_15,
    });
  };
  */

  const clearSkipIconTimeout = () => clearTimeout(skipIconTimeout.id);

  const rightId = useSelector(getPageRightId);
  const [showSkipIcon, setShowSkipIcon] = useState({ icon: '' });
  useEffect(() => {
    if (
      isMounted.current &&
      !showEdp &&
      !showSegmentViewer &&
      !showRelatedContent &&
      !showUpNext &&
      focusManager.isFocused(nowPlayingNav.id)
    ) {
      /*if (!isSkipForward15SecondsDisabled) {
        onSkipForward15();
      } else */ if (
        !isSkipForwardDisabled
      ) {
        onSkipForward(nowPlayingStore);
      }

      return clearSkipIconTimeout;
    }
  }, [rightId]);

  /***** Handles the RIGHT key press logic for skipping - END *****/

  /***** Handles the FFW key press logic for skipping - START *****/

  const ffwId = useSelector(getFFWKeyPressedId);
  useEffect(() => {
    if (
      isMounted.current &&
      !showEdp &&
      !showSegmentViewer &&
      !showRelatedContent &&
      !showUpNext &&
      focusManager.isFocused(nowPlayingNav.id)
    ) {
      if (!isSkipForwardDisabled) {
        onSkipForward(nowPlayingStore);
      }

      return clearSkipIconTimeout;
    }
  }, [ffwId]);

  /***** Handles the FFW key press logic for skipping - START *****/

  /***** Handles the LEFT key press logic for skipping - START *****/
  const isSkipBackwardDisabled =
    !skipButtonService.skipButtonsState.back || isLiveAndDisallowed;

  const onSkipBack = nowPlayingStore => {
    const dcmaInfo = {
      mediaType: nowPlayingStore.mediaType,
      dmcaInfo: nowPlayingStore.dmcaInfo,
      mediaId: nowPlayingStore.mediaId,
    };
    skipService.skipBack(dcmaInfo, livePointToSeekTo);
    skipButtonService.onSkip(skipButtonService.skipType.BACKWARD);
    clearSkipIconTimeout();
    setShowSkipIcon({ icon: SKIP_BACKWARD_ICON });
    skipIconTimeout.id = setTimeout(() => setShowSkipIcon({ icon: '' }), 1000);
    $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
      contentState: CONTENT_STATES.SKIP_PREVIOUS,
    });
  };

  //TODO: Uncomment once +/-15 seconds is ready to be implemented for Podcasts
  /* 
  const isSkipBackward15SecondsDisabled = isDisallowed || isRestricted;

  const onSkipBackward15 = () => {
    const seconds =
      mediaTimestampService.playheadTimestamp > 15
        ? mediaTimestampService.playheadTimestamp - 15
        : 0;
    seekService.seekThenPlay(seconds, false);
    clearSkipIconTimeout();
    setShowSkipIcon({ icon: SKIP_BACKWARD_15_SECONDS_ICON });
    skipIconTimeout.id = setTimeout(() => setShowSkipIcon({ icon: '' }), 1000);
    $badger.appActionMetricsHandler('CONTENT_STATE_CHANGE', {
      contentState: CONTENT_STATES.SKIP_BACKWARD_15,
    });
  };
  */

  const leftId = useSelector(getPageLeftId);
  useEffect(() => {
    if (
      isMounted.current &&
      !showEdp &&
      !showSegmentViewer &&
      !showRelatedContent &&
      !showUpNext &&
      focusManager.isFocused(nowPlayingNav.id)
    ) {
      /*if (!isSkipBackward15SecondsDisabled) {
        onSkipBackward15();
      } else */ if (
        !isSkipBackwardDisabled
      ) {
        onSkipBack(nowPlayingStore);
      }

      return clearSkipIconTimeout;
    }
  }, [leftId]);

  /***** Handles the LEFT key press logic for skipping - END *****/

  /***** Handles the REWIND key press logic for skipping - START *****/

  const rewindId = useSelector(getRewindKeyPressedId);
  useEffect(() => {
    if (
      isMounted.current &&
      !showEdp &&
      !showSegmentViewer &&
      !showRelatedContent &&
      !showUpNext &&
      focusManager.isFocused(nowPlayingNav.id)
    ) {
      if (!isSkipBackwardDisabled) {
        onSkipBack(nowPlayingStore);
      }

      return clearSkipIconTimeout;
    } else {
      isMounted.current = true;
      window.scroll(0, 0); //Restores position after the related carousel loads for the first time
    }
  }, [rewindId]);

  /***** Handles the REWIND key press logic for skipping - END *****/

  /*****  TIME AND PROGRESS HANDLING - START *****/
  const liveTimestamp = useObservable(mediaTimestampService.liveTime$);
  const shouldDisplayTimeRemaining =
    MediaUtil.isOnDemandMediaType(nowPlayingStore.type) ||
    MediaUtil.isMultiTrackAudioMediaType(nowPlayingStore.type);

  const getElapsedTime = () => {
    let playhead;
    if (
      mediaTimestampService.playheadTimestamp >
      mediaTimestampService.durationTimestamp
    ) {
      // if playhead is greater the duration--> return the duration value
      playhead = mediaTimestampService.durationTimestamp;
    } else {
      playhead = Math.floor(mediaTimestampService.playheadTimestamp);
    }

    return getTime(playhead);
  };

  const getDuration = () => {
    let playhead;
    if (
      mediaTimestampService.playheadTimestamp >
      mediaTimestampService.durationTimestamp
    ) {
      // if playhead is greater the duration--> return the duration value
      playhead = mediaTimestampService.durationTimestamp;
    } else {
      playhead = Math.floor(mediaTimestampService.playheadTimestamp);
    }
    return getTime(mediaTimestampService.durationTimestamp - playhead);
  };

  const getAbbreviatedDuration = () => {
    const value = mediaTimestampService.durationTimestamp;
    const isNumber: boolean = !isNaN(value);
    const isValidValue: boolean = value >= 1;

    if (!isNumber || !isValidValue) {
      return ZERO_TIME;
    }

    let hours: string = String(Math.floor(value / 3600));
    let minutes: string = String(Math.floor(value / 60) % 60);

    if (Number(hours) < 1) {
      hours = '';
    }

    if (Number(minutes) === 0 && Number(hours) >= 1) {
      minutes = '';
    }

    return `${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm' : ''}`;
  };

  const [abbreviatedDuration, setAbbreviatedDuration] = useState('');
  useEffect(() => {
    if (mediaTimestampService && mediaTimestampService.durationTimestamp) {
      setAbbreviatedDuration(getAbbreviatedDuration());
    }
  }, [mediaTimestampService && mediaTimestampService.durationTimestamp]);

  const storageService = ServiceFactory.getInstance(
    StorageService,
  ) as StorageService;

  const getProgress = (playheadTimestamp, isLive) => {
    if (storageService.getItem(StorageKeyConstant.IS_DEEPLINKED) && !isLive) {
      if (
        playheadTimestamp >=
        mediaTimestampService.getDurationTimestamp() - 15
      ) {
        $badger.showToaster('ExitMessage.PressExit');
        storageService.removeItem(StorageKeyConstant.IS_DEEPLINKED);
      }
    }

    let percentage = 0;
    if (playheadTimestamp) {
      if (isLive) {
        //If live, the playheadTimestamp parameter will be of type IPlayHead. Otherwise, it will be just a number.
        const isoEndTime = episode && episode.times && episode.times.isoEndTime;
        const isoStartTime =
          episode && episode.times && episode.times.isoStartTime;
        const isoEndTimeInMs = moment(isoEndTime, moment.ISO_8601).valueOf();
        const isoStartTimeInMs = moment(
          isoStartTime,
          moment.ISO_8601,
        ).valueOf();
        const durationInMs = isoEndTimeInMs - isoStartTimeInMs;

        if (isoEndTime) {
          percentage =
            ((playheadTimestamp.currentTime.zuluMilliseconds -
              isoStartTimeInMs) *
              100) /
            durationInMs;
        } else {
          percentage = 0;
        }
      } else {
        percentage =
          (playheadTimestamp / mediaTimestampService.getDurationTimestamp()) *
          100;
      }
    }

    return percentage < 100 ? percentage : 0;
  };

  const getLiveShowRestartPosition = currentEpisodeZuluStartTime => {
    const deltaInMs =
      (playheadTimestampForLive as any).currentTime.zuluMilliseconds -
      currentEpisodeZuluStartTime;
    const seekToTimeInMs =
      (playheadTimestampForLive as any).currentTime.milliseconds - deltaInMs;
    return seekToTimeInMs / 1000;
  };

  const getLivePointProgress = playheadTimestamp => {
    if (storageService.getItem(StorageKeyConstant.IS_DEEPLINKED)) {
      if (
        playheadTimestamp >=
        mediaTimestampService.getDurationTimestamp() - 15
      ) {
        $badger.showToaster('ExitMessage.PressExit');
        storageService.removeItem(StorageKeyConstant.IS_DEEPLINKED);
      }
    }

    const percentage =
      (playheadTimestamp / mediaTimestampService.getDurationTimestamp()) * 100;
    return percentage < 100 ? percentage : 0;
  };

  useEffect(() => {
    return () => {
      storageService.removeItem(StorageKeyConstant.IS_DEEPLINKED);
    };
  }, []);

  const getShowAirTime = (episode, type = 'isoStartTime') => {
    const isoTime =
      (episode && episode.times && episode.times[type]) || ZERO_TIME;
    const time = moment(isoTime, moment.ISO_8601).isValid()
      ? moment(isoTime).format('LT')
      : ZERO_TIME;
    return time.trim() || ZERO_TIME;
  };

  const isBehindCurrentShowStart = (
    playheadTimestampForLive,
    latestEpisodeStartTime,
  ) => {
    if (latestEpisodeStartTime) {
      const currentPosition =
        (playheadTimestampForLive &&
          (playheadTimestampForLive as any).currentTime.zuluMilliseconds) ||
        0;
      return currentPosition < latestEpisodeStartTime;
    } else {
      return false;
    }
  };

  const mediaTimeLineService = ServiceFactory.getInstance(
    MediaTimeLineService,
  ) as MediaTimeLineService;
  const [latestEpisodeStartTime, setLatestEpisodeStartTime] = useState(0);
  useEffect(() => {
    const subscription = mediaTimeLineService.mediaTimeLine.subscribe(data => {
      const lastestEpisode = findMediaItemByTimestamp(
        moment().valueOf(),
        data.episodes,
      );
      setLatestEpisodeStartTime(lastestEpisode?.times?.zuluStartTime || 0);
    });

    return () => subscription.unsubscribe();
  }, []);

  /*****  TIME AND PROGRESS HANDLING - END *****/

  const channelLogoUrl = useSelector(getChannelLogoUrl); //Channel logo is populated with the artist's profile image on Pandora Stations, for some reason
  const albumImageUrl = useSelector(getAlbumImage);
  const posterImageUrl = useSelector(getPosterImageUrl);
  const artistName = useSelector(getArtistName);
  const trackName = useSelector(getTrackName);
  const episode = useSelector(getEpisode);
  const isOnDemand = useSelector(getIsOnDemand);

  //Determines which background image to place, depending if it's a live channel, if there's a live show or if it's AOD for a past show
  const channelBackgroundImage = useSelector(getBackgroundImage);
  const showBackgroundImage = useSelector(getShowBackgroundImage);
  const isIrisPodcast = useSelector(getIsIrisPodcast);
  const carouselStore = useSelector(selectCarouselState); //Holds the show's image to be replaced with the channel's for AOD only
  const [nowPlayingBackgroundImage, setNowPlayingBackgroundImage] = useState(
    '',
  );
  useEffect(() => {
    let nowPlayingBg = '';
    if (isAod || isIrisPodcast) {
      nowPlayingBg =
        carouselStore.nowPlayingCarousel &&
        carouselStore.nowPlayingCarousel.pageBackground &&
        carouselStore.nowPlayingCarousel.pageBackground.imageLink;
      nowPlayingBg = nowPlayingBg || '';
    } else if (isAIC) {
      const imageObject =
        channel &&
        channel.imageList &&
        channel.imageList.find(
          imageObj =>
            imageObj.name === TILE_CHANNEL_BACKGROUND ||
            imageObj.name === NAME_BACKGROUND,
        );
      nowPlayingBg = imageObject && imageObject.url;
    }

    if (!nowPlayingBg) {
      setNowPlayingBackgroundImage(
        showBackgroundImage || channelBackgroundImage,
      );
    } else {
      setNowPlayingBackgroundImage(nowPlayingBg);
    }
  }, [
    isAod,
    isIrisPodcast,
    isAIC,
    channel,
    carouselStore && carouselStore.nowPlayingCarousel,
  ]);

  //Attempts to find the logo for the channel for which this AOD is from, and replace it with the show's own logo. Otherwise, stays empty.
  const [channelLogoForAod, setChannelLogoForAod] = useState('');
  useEffect(() => {
    let nowPlayingLogo = '';
    if (isOnDemand) {
      const realChannelLogoObject =
        channel &&
        channel.imageList &&
        channel.imageList.find(
          imageObject => imageObject.name === NAME_COLOR_CHANNEL_LOGO,
        );
      nowPlayingLogo = realChannelLogoObject && realChannelLogoObject.url;
      nowPlayingLogo = nowPlayingLogo || '';

      if (nowPlayingLogo) {
        setChannelLogoForAod(nowPlayingLogo);
      }
    }
  }, [isOnDemand, channel]);

  //Extracts the artist name to be placed instead of the logo for Pandora Stations
  const [artistRadioName, setArtistradioName] = useState('');
  useEffect(() => {
    if (isSeededRadio && nowPlayingStore && nowPlayingStore.channelName) {
      const indexOfLastRadio = nowPlayingStore.channelName
        .toLowerCase()
        .lastIndexOf('radio');
      setArtistradioName(
        nowPlayingStore.channelName.substring(0, indexOfLastRadio),
      );
    }
  }, [isSeededRadio, nowPlayingStore && nowPlayingStore.channelName]);

  const [emissionDay, setEmissionDay] = useState('');
  useEffect(() => {
    if (
      (isAod || isIrisPodcast || isVideo) &&
      episode &&
      episode.originalIsoAirDate
    ) {
      if (moment(episode.originalIsoAirDate).isSame(moment(), 'day')) {
        setEmissionDay('Today');
      } else {
        setEmissionDay(
          moment(episode.originalIsoAirDate).format('MMM D, YYYY'),
        );
      }
    }
  }, [isAod, isIrisPodcast, isVideo, episode && episode.originalIsoAirDate]);

  return (
    <Page
      className="now-playing-page"
      nav={nowPlayingNav}
      onClick={togglePlayPause}
      onFocus={() => {
        setShowEdp(false);
        setShowSegmentViewer(false);
        setShowRelatedContent(false);
      }}
      autoFocus
    >
      {!isSeededRadio && !isVideo && !showUpNext && (
        <div
          className={`now-playing-background ${
            isBackgroundLoaded ? 'loaded' : ''
          }`}
          style={{
            backgroundImage: isBackgroundLoaded
              ? `url("${nowPlayingBackgroundImage}")`
              : '',
            backgroundColor: 'black',
          }}
        />
      )}
      {isSeededRadio && artistRadioName && (
        <div className="now-playing-artist-radio-background">
          <div className="now-playing-artist-radio-gradient-overlay" />
          <div
            className="now-playing-artist-radio-image"
            style={{
              backgroundImage: isArtistRadioImagLoaded
                ? `url("${channelLogoUrl}")`
                : '',
            }}
          />
        </div>
      )}
      <img
        ref={backgroundImageRef}
        src={nowPlayingBackgroundImage}
        style={{ display: 'none' }}
      />
      {isSeededRadio && artistName && (
        <img
          ref={artistRadioImageRef}
          src={channelLogoUrl}
          style={{ display: 'none' }}
        />
      )}
      {isVideo && !showUpNext && (
        <Video
          channelLogoForVod={channelLogoForAod}
          channelLogoUrl={channelLogoUrl}
          channelName={channel && channel.name}
          showLongTitle={show && show.longTitle}
          episodeLongTitle={episode && episode.longTitle}
          emissionDay={emissionDay}
          abbreviatedDuration={abbreviatedDuration}
          showSegmentViewer={showSegmentViewer}
          showEdp={showEdp}
          showRelatedContent={showRelatedContent}
          showSkipIcon={showSkipIcon}
          isPaused={isPaused}
          SKIP_FORWARD_15_SECONDS_ICON={SKIP_FORWARD_15_SECONDS_ICON}
          SKIP_BACKWARD_15_SECONDS_ICON={SKIP_BACKWARD_15_SECONDS_ICON}
          getElapsedTime={getElapsedTime}
          getProgress={getProgress}
          getDuration={getDuration}
          playheadTimestamp={mediaTimestampService.playheadTimestamp}
          CONTENT_STATES={CONTENT_STATES}
        />
      )}
      <EDPInformationDrawer
        nav={edpInformationDrawerNav}
        showEdp={showEdp}
        albumImageUrl={
          isIrisPodcast ? channelLogoForAod || channelLogoUrl : albumImageUrl
        }
        channelLogoUrl={channelLogoForAod || channelLogoUrl}
        channelBackgroundImage={nowPlayingBackgroundImage}
        channel={channel}
        episode={episode}
        show={show}
        episodeStartTime={getShowAirTime(episode, 'isoStartTime')}
        episodeEndTime={getShowAirTime(episode, 'isoEndTime')}
        abbreviatedDuration={abbreviatedDuration}
        onFocus={() => setShowEdp(true)}
        isLive={isLive}
        isDisallowed={isDisallowed}
        isRestricted={isRestricted}
        isLiveAndDisallowed={isLiveAndDisallowed}
        isOnDemand={isOnDemand}
        isSeededRadio={isSeededRadio}
        isAIC={isAIC}
        isAod={isAod}
        isIrisPodcast={isIrisPodcast}
        isVod={isVod}
        artistName={artistName}
        trackName={trackName}
        nowPlayingStore={nowPlayingStore}
        setShowEdp={setShowEdp}
        nowPlayingNavId={nowPlayingNav.id}
        dmcaService={dmcaService}
        onSkipForward={onSkipForward}
        onSkipBack={onSkipBack}
        isSkipForwardDisabled={isSkipForwardDisabled}
        setShowSegmentViewer={setShowSegmentViewer}
        emissionDay={emissionDay}
        posterImageUrl={posterImageUrl}
        mediaPlayerService={mediaPlayerService}
        isPlayheadBehindLive={isPlayheadBehindLive}
        getLiveShowRestartPosition={getLiveShowRestartPosition}
      />
      {showSegmentViewer && (
        <SegmentViewer
          showTitle={show && show.longTitle}
          episode={episode}
          nowPlayingNavId={nowPlayingNav.id}
          showLogo={channelLogoUrl}
          CONTENT_STATES={CONTENT_STATES}
        />
      )}
      {!showEdp && !showSegmentViewer && !showRelatedContent && !isVideo && (
        <div className="now-playing-container">
          <div className={`now-playing-help-text ${isPaused ? 'loaded' : ''}`}>
            <div className="press-down-help-text">
              <Chevron />
              Press Down for More Info
            </div>
            <div className="press-up-help-text">
              <Chevron /> Press Up for Related
            </div>
          </div>
          <div
            className={`now-playing-logo ${isSeededRadio ? 'is-radio' : ''}
            ${isIrisPodcast ? 'is-podcast' : ''}
            ${isLogoLoaded ? 'loaded' : ''}
            `}
            style={{
              backgroundImage:
                !isSeededRadio && isLogoLoaded
                  ? `url("${channelLogoForAod ||
                      channelLogoUrl}?&width=480&height=384&preserveAspect=true")`
                  : '',
            }}
          >
            {isSeededRadio && (
              <div className="artist-radio-name-container">
                <div className="artist-radio-name">{artistRadioName}</div>
                {artistRadioName && (
                  <div className="artist-radio-keyword">Radio</div>
                )}
              </div>
            )}
            <img
              src={
                channelLogoForAod || channelLogoUrl
                  ? `${channelLogoForAod ||
                      channelLogoUrl}?&width=480&height=384&preserveAspect=true`
                  : ''
              }
              ref={logoImageRef}
              style={{ display: 'none' }}
            ></img>
          </div>
          <div className="now-playing-info-bar">
            <div
              className={`${
                albumImageUrl || (!albumImageUrl && isIrisPodcast)
                  ? 'now-playing-album-cover-container'
                  : 'now-playing-fallback-cover-container'
              }`}
            >
              <div
                className={`${
                  albumImageUrl || (!albumImageUrl && isIrisPodcast)
                    ? 'now-playing-album-cover'
                    : 'now-playing-fallback-cover'
                } ${isPaused || showSkipIcon.icon ? 'is-paused' : ''}`}
                style={{
                  backgroundImage: albumImageUrl
                    ? `url("${albumImageUrl}")`
                    : (channelLogoForAod || channelLogoUrl) && !isSeededRadio
                    ? `url("${channelLogoForAod ||
                        channelLogoUrl}?&width=180&height=144&preserveAspect=true")`
                    : '',
                }}
              ></div>
              <div
                className={`playback-icon-container ${
                  isPaused || showSkipIcon.icon ? 'loaded' : ''
                }`}
              >
                <PlayIcon style={{ display: isPaused ? 'block' : 'none' }} />
                <SkipForward
                  style={{
                    display:
                      showSkipIcon.icon === SKIP_FORWARD_ICON
                        ? 'block'
                        : 'none',
                  }}
                />
                <SkipBackward
                  style={{
                    display:
                      showSkipIcon.icon === SKIP_BACKWARD_ICON
                        ? 'block'
                        : 'none',
                  }}
                />
                <SkipForward15Seconds
                  style={{
                    display:
                      showSkipIcon.icon === SKIP_FORWARD_15_SECONDS_ICON
                        ? 'block'
                        : 'none',
                  }}
                />
                <SkipBackward15Seconds
                  style={{
                    display:
                      showSkipIcon.icon === SKIP_BACKWARD_15_SECONDS_ICON
                        ? 'block'
                        : 'none',
                  }}
                />
              </div>
            </div>
            <div className="now-playing-media-info-container">
              <div className="now-playing-media-info">
                <span className="now-playing-artist">
                  {isAod && episode && episode.longTitle}
                  {!isAod && (artistName || '')}
                </span>
                {trackName && !isIrisPodcast ? (
                  <span> — </span>
                ) : (
                  <span>&nbsp;</span>
                )}
                <span className="now-playing-track">
                  {isAod &&
                    episode &&
                    moment(episode.originalIsoAirDate).format('MMMM D, YYYY')}
                  {!isAod && !isIrisPodcast && (trackName || '')}
                </span>
              </div>
              <div className="now-playing-channel-info">
                <span className="now-playing-channel">
                  {isSeededRadio ? 'Pandora Station' : ''}
                  {!isSeededRadio &&
                    !isIrisPodcast &&
                    (isAod && channel
                      ? `${channel.name ? channel.name + ' - On Demand' : ''} `
                      : `Ch ${channel.channelNumber || ''}`)}
                  {!isSeededRadio &&
                    isIrisPodcast &&
                    ((channel && channel.name) || '')}
                </span>
                {((isSeededRadio &&
                  artistRadioName &&
                  nowPlayingStore &&
                  nowPlayingStore.channelName) ||
                  (!isSeededRadio &&
                    !isIrisPodcast &&
                    episode &&
                    episode.longTitle) ||
                  isAIC) && <span className="dot-icon" />}
                <span className="now-playing-episode">
                  {isSeededRadio &&
                    artistRadioName &&
                    nowPlayingStore &&
                    nowPlayingStore.channelName}
                  {!isSeededRadio && isAod && ((show && show.longTitle) || '')}
                  {!isSeededRadio &&
                    !isAod &&
                    !isIrisPodcast &&
                    ((episode && episode.longTitle) || '')}
                  {isAIC && ((channel && channel.name) || '')}
                </span>
              </div>
              <div className="progress-bar">
                <div
                  className="current-live-position"
                  style={{
                    width:
                      isSeededRadio ||
                      isAod ||
                      isIrisPodcast ||
                      isAIC ||
                      (isLive &&
                        isBehindCurrentShowStart(
                          playheadTimestampForLive,
                          latestEpisodeStartTime,
                        ))
                        ? '100%'
                        : `${getLivePointProgress(liveTimestamp)}%`,
                  }}
                />
                <div
                  className="current-listening-position"
                  style={{
                    width: `${getProgress(
                      isLive ? playheadTimestampForLive : playheadTimestamp,
                      isLive,
                    )}%`,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="now-playing-timestamp-and-skips-container">
            <div className="now-playing-skips-remaining">
              {skipButtonService.displayMessage
                ? skipButtonService.getSkipMessageText()
                : ''}
            </div>
            {!isSeededRadio && (
              <div
                className={`now-playing-timestamp-container ${
                  isPaused ? 'loaded' : ''
                }`}
              >
                <div className="start-time">
                  {!shouldDisplayTimeRemaining &&
                    getShowAirTime(episode, 'isoStartTime')}
                </div>
                <div className="end-time">
                  {shouldDisplayTimeRemaining
                    ? getDuration()
                    : getShowAirTime(episode, 'isoEndTime')}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={`now-playing-gradient-overlay ${
          (isPaused || showEdp) && !showSegmentViewer && !showUpNext
            ? 'loaded'
            : ''
        }`}
      />
      <RelatedContent
        nav={relatedContentNav}
        carouselStore={carouselStore}
        onFocus={() => setShowRelatedContent(true)}
        showRelatedContent={showRelatedContent}
        nowPlayingNavId={nowPlayingNav.id}
      />
    </Page>
  );
};
